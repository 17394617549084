<script lang="ts" setup>
// Dependencies - Vendor
import { computed, shallowRef } from 'vue';
import { useRoute, useRouter } from 'vue-router';

// Dependencies - Framework
import { actionIcons, optionIcons } from '@/assets/icons';
import { useMainStore } from '@/stores/mainStore';
import type { OptionConfig, WorkbenchConfig } from '../WorkbenchConfig';

// Dependencies - Data
import { workbenchConfigData } from '../WorkbenchConfig';

// Dependencies - Component
import Button from '@/components/Button.vue';
import OptionDrawerFooter from './OptionDrawerFooter.vue';
import OptionDrawerSplitButton from './OptionDrawerSplitButton.vue';

// Global State
const mainStore = useMainStore();
const router = useRouter();
const route = useRoute();

// Non-Reactive Variables
const pathSegments = route.path.split('/');
mainStore.optionDrawerPanelId = pathSegments.length > 2 ? pathSegments[2] : 'home';
const workbenchConfig: WorkbenchConfig = workbenchConfigData;

// Reactive Variables & Watchers
const benchtopConfig = shallowRef(workbenchConfig.benchtops[2]);
const labelsAreVisible = computed(() => optionDrawerStateId.value === 'floating' || (optionDrawerStateId.value === 'expanded' && optionDrawerWidthId.value === 'wide'));
const optionDrawerPanelId = computed(() => mainStore.optionDrawerPanelId);
const optionDrawerStateId = computed(() => mainStore.optionDrawerStateId);
const optionDrawerMenuWidth = computed(() => mainStore.optionDrawerMenuWidth);
const optionDrawerWidthId = computed(() => mainStore.optionDrawerWidthId);

// UI Event Handlers - Navigate To Benchtop
const navigateToBenchtop = (index: number) => {
    benchtopConfig.value = workbenchConfig.benchtops[index];
    navigateToOption(workbenchConfig.benchtops[index].options[0], 'home');
};

// UI Event Handlers - Navigate To Option
const navigateToOption = (optionConfig: OptionConfig, optionId: string) => {
    mainStore.optionDrawerPanelId = optionId;
    if (optionDrawerStateId.value === 'floating') {
        mainStore.collapseOptionDrawer();
    }
    router.push({ path: optionConfig.to });
};

// UI Event Handlers - Toggle Option Drawer
const toggleOptionDrawer = () => mainStore.toggleOptionDrawer();
</script>

<template>
    <div
        class="flex max-w-[calc(100%-16px)] flex-col border"
        :class="
            optionDrawerStateId === 'collapsed'
                ? 'absolute h-auto border-transparent dark:border-transparent'
                : `h-full bg-surface-light dark:bg-surface-dark ${optionDrawerStateId === 'floating' ? 'absolute border-line-light dark:border-line-dark' : 'border-y-transparent border-l-transparent border-r-line-light dark:border-y-transparent dark:border-l-transparent dark:border-r-line-dark'}`
        "
        :style="{ width: `${optionDrawerMenuWidth || 72}px` }"
    >
        <!-- Toggle Button -->
        <div class="flex h-14 flex-none flex-col justify-center px-4">
            <Button class="flex-none gap-x-2" type="option" @click="toggleOptionDrawer()">
                <div v-html="actionIcons.toggleSVG" class="flex h-9 w-9 flex-none items-center justify-center" />
                <div v-if="labelsAreVisible" class="truncate pr-2 text-left">{{ $t('workbench.label') }}</div>
            </Button>
        </div>

        <!-- Divider -->
        <hr v-if="optionDrawerStateId !== 'collapsed'" class="mx-4 flex-none border-line-light dark:border-line-dark" />

        <!-- Options Panel -->
        <div v-if="optionDrawerStateId !== 'collapsed'" class="flex flex-1 flex-col overflow-y-scroll overscroll-y-none px-4" :class="optionDrawerStateId === 'floating' ? '' : ''">
            <!-- Benchtop Options -->
            <div class="flex flex-1 flex-col pb-3">
                <template v-for="(optionConfig, optionIndex) in benchtopConfig.options" :key="optionIndex">
                    <hr v-if="optionConfig.isNewGroup" class="mt-2 flex-none border-line-light dark:border-line-dark" />

                    <OptionDrawerSplitButton
                        v-if="optionIndex === 0"
                        :benchtopConfig="benchtopConfig"
                        :labelsAreVisible="labelsAreVisible"
                        :optionConfig="optionConfig"
                        @navigateToBenchtop="(index) => navigateToBenchtop(index)"
                        @navigateToOption="(optionConfig, optionId) => navigateToOption(optionConfig, optionId)"
                    />

                    <Button v-else class="relative mt-2 flex-none gap-x-2" :remainsActive="optionConfig.id === optionDrawerPanelId" type="option" @click="navigateToOption(optionConfig, optionConfig.id)">
                        <div v-html="optionIcons[`${optionConfig.id}SVG`]" class="flex h-9 w-9 flex-none items-center justify-center" />
                        <div v-if="labelsAreVisible" class="truncate pr-2 text-left">{{ $t(`${optionConfig.id}.label`) }}</div>
                    </Button>
                </template>
            </div>

            <!-- Footer Options -->
            <OptionDrawerFooter :labelsAreVisible="labelsAreVisible" />
        </div>
    </div>
</template>

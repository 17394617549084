<script lang="ts" setup>
// Dependencies - Vendor
import { computed, defineAsyncComponent, onErrorCaptured, ref, watch } from 'vue';

// Dependencies - Framework
import { DEFINE_ASYNC_COMPONENT_TIMEOUT, handleError } from '@/globals';
import { useMainStore } from '@/stores/mainStore';

// Dependencies - Component
import Alert from '@/components/Alert.vue';
import DefaultMask from '@/components/DefaultMask.vue';
import ComponentLoadingMask from '@/components/ComponentLoadingMask.vue';
import ComponentUnavailableMask from '@/components/ComponentUnavailableMask.vue';
import OptionDrawer from './optionDrawer/OptionDrawer.vue';
import ToolDrawer from '@/views/toolDrawer/ToolDrawer.vue';
import ToolMenu from './toolMenu/ToolMenu.vue';

// Dependencies - Component - Asynchronous
const SignInUpDialog = defineAsyncComponent({
    loader: () => import('@/views/sessionAuth/SignInUpDialog.vue'),
    errorComponent: ComponentUnavailableMask,
    loadingComponent: ComponentLoadingMask,
    timeout: DEFINE_ASYNC_COMPONENT_TIMEOUT
});
const SignOutDialog = defineAsyncComponent({
    loader: () => import('@/views/sessionAuth/SignOutDialog.vue'),
    errorComponent: ComponentUnavailableMask,
    loadingComponent: ComponentLoadingMask,
    timeout: DEFINE_ASYNC_COMPONENT_TIMEOUT
});

// Global State
const mainStore = useMainStore();

// Reactive Variables & Watchers
const alertConfigs = computed(() => mainStore.alertConfigs);
const navigationDepth = computed(() => mainStore.navigationDepth); // TODO: What was this for?
const optionDrawerStateId = computed(() => mainStore.optionDrawerStateId);
const optionDrawerWidth = computed(() => mainStore.optionDrawerWidth);
const routerComponent = ref();
const sessionAuthDialogIsActive = computed(() => mainStore.sessionAuthDialogIsActive);
const sessionUser = computed(() => mainStore.sessionUser);
const toolDrawerStateId = computed(() => mainStore.toolDrawerStateId);
const toolDrawerWidth = computed(() => mainStore.toolDrawerWidth);
const toolMenuDropdownIsOpen = computed(() => mainStore.toolMenuDropdownIsOpen);
const workbenchModuleIsLoading = computed(() => mainStore.workbenchModuleIsLoading);
const workbenchMaskIsActive = computed(() => mainStore.workbenchMaskIsActive);

// TODO: Outstanding
watch(workbenchModuleIsLoading, (newValue) => console.log(5555, newValue));

// Lifecycle Event Handlers (ErrorCaptured
onErrorCaptured((error) => {
    // if (error.message.startsWith('Failed to fetch dynamically imported module')) {
    //     return false;
    // }
    // if (error.message.startsWith('Async component timed out')) {
    //     return false;
    // }
    return handleError(error, { locator: 'Workbench.1' });
});

// UI Event Handlers - Collapse Drawer
const collapseDrawer = () => {
    if (mainStore.optionDrawerStateId === 'floating') {
        mainStore.collapseOptionDrawer();
    }
    if (mainStore.toolDrawerStateId === 'floating') {
        mainStore.collapseToolComponents();
    }
};
</script>

<template>
    <!-- Layers -->
    <!-- z-10: Content -->
    <!-- z-20: View Header (see '@/components/ViewHeader.vue') -->
    <!-- z-30: Option Drawer & Tool Drawer (when NOT floating) -->
    <!-- z-40: Drawer Floating Mask -->
    <!-- z-50: Tool Menu, Option Drawer (when floating) & Tool Drawer (when floating) -->
    <!-- z-60: Workbench Mask & Dialog Panel -->
    <!-- z-70: Alert Panel -->

    <!-- Workbench -->
    <div v-show="!sessionAuthDialogIsActive" class="h-full">
        <!-- Tool Menu - Positioned in top right corner. -->
        <ToolMenu class="fixed right-0 top-0 z-50 bg-backdrop-light dark:bg-backdrop-dark" @action="(id: string) => routerComponent.handleAction(id)" />

        <!-- Content -->
        <div class="flex h-full">
            <OptionDrawer class="flex-none" :class="optionDrawerStateId === 'floating' ? 'z-50' : 'z-30'" />

            <div class="relative z-10 h-full" :style="{ width: `calc(100% - ${optionDrawerWidth}px - ${toolDrawerStateId === 'expanded' ? toolDrawerWidth : 0}px)` }">
                <RouterView v-slot="{ Component, route }">
                    <Transition name="fade">
                        <ComponentLoadingMask v-if="workbenchModuleIsLoading" />
                        <component v-else-if="Component" :is="Component" :key="route.name" />
                    </Transition>
                </RouterView>
            </div>

            <ToolDrawer class="mt-14 flex-none" :class="toolDrawerStateId === 'floating' ? 'z-50' : 'z-30'" />
        </div>
    </div>

    <!-- Drawer Mask - Only displayed when the option drawer is floating. -->
    <DefaultMask v-if="optionDrawerStateId === 'floating' || toolMenuDropdownIsOpen || toolDrawerStateId === 'floating'" class="z-40" @click="collapseDrawer()" />

    <!-- Workbench Mask - Sits above all elements except the alert panel. -->
    <DefaultMask v-if="workbenchMaskIsActive" class="z-60" />

    <!-- Sign In/Up and Sign Out Dialogs - See '#dialogPanel' in 'index.html' with 'z-index: 60'. -->
    <Teleport v-if="sessionAuthDialogIsActive" to="#dialogPanel">
        <Transition name="modal">
            <DefaultMask :isOpaque="false">
                <SignOutDialog v-if="sessionUser" />
                <SignInUpDialog v-else />
            </DefaultMask>
        </Transition>
    </Teleport>

    <!-- Alerts - See '#alertPanel' in 'index.html' with 'z-index: 70'. -->
    <Teleport to="#alertPanel">
        <template v-for="alertConfig in alertConfigs" :key="alertConfig.id">
            <Alert :config="alertConfig" />
        </template>
    </Teleport>
</template>

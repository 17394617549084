<script lang="ts" setup>
// Dependencies - Vendor
import { onMounted, onUnmounted, type PropType, ref } from 'vue';

// Dependencies - Framework
import { useMainStore } from '@/stores/mainStore';
import { alertIcons } from '@/assets/icons';

// Dependencies - Component
import Button from '@/components/Button.vue';

// Interfaces/Types - Alert
export interface AlertConfig {
    actionCallback?: (config: AlertConfig) => void;
    actionLabel?: string;
    categoryId?: 'offline' | 'online';
    expiresIn?: number;
    id?: string;
    message: string;
    notes?: string;
    occurrenceId?: string;
    typeId: AlertTypeId;
}
export type AlertTypeId = 'error' | 'info' | 'success' | 'warning';

// Global State
const mainStore = useMainStore();

// Attributes, Emitted Events, Options, Properties & Slots
const props = defineProps({
    config: { required: true, type: Object as PropType<AlertConfig> }
});

// Non-Reactive Variables
let elapsedTime = 0;
const expiresIn = props.config.expiresIn || 0;
let expiresInTimer: number | undefined = undefined;
const updateInterval = 100;

// Reactive Variables & Watchers
const percentComplete = ref(0);

// Lifecycle Event Handlers
onMounted(() => (expiresIn ? setTimer() : void 0));
onUnmounted(() => clearTimer());

// UI Event Handlers - Click
const click = () => (expiresIn ? (expiresInTimer ? clearTimer() : setTimer()) : void 0);

// UI Event Handlers - Close
const close = (alertConfig: AlertConfig) => mainStore.alertConfigs.splice(mainStore.alertConfigs.indexOf(alertConfig), 1);

// Utilities - Build Icon Color Classes
const buildIconColorClasses = (typeId: AlertTypeId): string => {
    if (typeId === 'error') {
        return 'text-red-500 dark:text-red-300';
    }
    if (typeId === 'success') {
        return 'text-green-500 dark:text-green-300';
    }
    if (typeId === 'warning') {
        return 'text-yellow-500 dark:text-yellow-300';
    }
    return 'text-blue-500 dark:text-blue-300';
};

// Utilities - Clear Timer
const clearTimer = () => {
    window.clearInterval(expiresInTimer);
    expiresInTimer = undefined;
};

// Utilities - Set Timer
const setTimer = () => {
    expiresInTimer = window.setInterval(() => {
        elapsedTime += updateInterval;
        percentComplete.value = (elapsedTime / expiresIn) * 100;
        if (elapsedTime >= expiresIn) {
            window.clearInterval(expiresInTimer);
            close(props.config);
        }
    }, updateInterval);
};
</script>

<template>
    <div class="relative mt-3 overflow-hidden rounded-md border border-neutral-200 bg-surface-light dark:border-neutral-700 dark:bg-neutral-800 dark:bg-surface-dark" @click.stop="click()">
        <!-- Message -->
        <div class="mt-3 flex w-full gap-x-2 px-4 leading-tight">
            <div v-html="alertIcons[`${config.typeId}SVG`]" class="h-6 w-6 flex-none items-center justify-center" :class="buildIconColorClasses(config.typeId)" />
            <div class="dp-break-word mr-10 mt-0.5 flex-1 font-medium">{{ config.message }}</div>
            <Button class="absolute right-2 top-2 flex-none border-none" type="icon" @click.stop="close(config)">
                <div v-html="alertIcons.closeSVG" class="h-6 w-6 items-center justify-center" />
            </Button>
        </div>

        <!-- Optional Notes & Action -->
        <div class="px-4 text-sm">
            <!-- Notes -->
            <div v-if="config.notes" class="dp-break-word whitespace-pre-wrap px-0.5">{{ config.notes }}</div>

            <!-- Action -->
            <div v-if="config.actionCallback && config.actionLabel" class="mt-3">
                <Button type="alert" @click.stop="config.actionCallback(config)">{{ config.actionLabel }}</Button>
            </div>
        </div>

        <!-- Expires In Progress Bar -->
        <div class="mt-3 h-1 w-full">
            <div class="h-full bg-neutral-300 dark:bg-neutral-600" :style="`width: ${percentComplete.toFixed(1)}%`" />
        </div>
    </div>
</template>
